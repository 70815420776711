import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

export interface WidgetPuxWysiwygType extends WidgetBase {
  contentType: 'PuxWysiwyg'
  wysiwygSmallContainer: boolean
  htmlBody: PuxWysiwygHtmlBodyType
}

const WidgetPuxWysiwyg: FunctionComponent<WidgetPuxWysiwygType> = (props) => (
  <WidgetContainer {...props.puxWidgetProperties} {...props.metadata}>
    <PuxWysiwyg
      content={props.htmlBody}
      wysiwygId={props.contentItemId}
      small={props.wysiwygSmallContainer}
    />
  </WidgetContainer>
)
export default WidgetPuxWysiwyg
